$.fn.mapVerticalScrollToHorizontal = function() {
    'use strict';

    $(this).on('mousewheel', function(event) {
        if (event.deltaY) {
            // Movement on Y axis
            var scrollDistance = event.deltaY * event.deltaFactor;
            $(this).scrollLeft($(this).scrollLeft() - scrollDistance);

            // Prevent scrolling
            event.preventDefault();
        }
    });
};
