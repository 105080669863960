/* globals $, _, ga, appConfig, pageData, MediaQuery, Modernizr, objectFitImages, objectFitVideos, Rellax */

/* exported App */

'use strict';

var App = (function() {

    var config = {
            selectors: {
                window: window,
                document: document,
                body: 'body',
                navToggle: '.js-nav-toggle',
                smoothScrolling: '.js-smooth-scroll',

                pageTitle: '.page-title',

                cookieNotice: '.js-cookie-notice',
                cookieAccept: '.js-cookie-accept',

                parallaxImage: '.js-parallax-image',

                contentPreviewContainer: '.js-content-preview-container',
                contentPreviewIntro: '.js-content-preview-intro',
                contentPreviewContent: '.js-content-preview-content',
                contentPreviewToggle: '.js-content-preview-toggle',

                contentToggleContainer: '.js-content-toggle-container',
                contentToggleButton: '.js-content-toggle-button',
                contentToggleContent: '.js-content-toggle-content',

                serviceContainer: '#js-service-content',
                serviceLink: '.js-service-link',
                servicePreview: '.js-service-preview',

                blogList: '.js-blog-list',
                blogContainer: '#js-blog-content',
                blogLink: '.js-blog-link',
                blogPreview: '.js-blog-preview',
                blogPreviewTitle: '.js-blog-preview-title',
                blogCategoryPreview: '.js-blog-category-preview',
                activeBlogPreview: '.js-blog-preview.is-active',

                contentFooter: '#js-content-footer',

                processList: '.js-process-list',
            },

            smoothScrollingSpeed: 600,
            objectFitInterval: 3000,
            scrollTolerance: 50,
        },

        selectors = {},

        elements = {}, $e,



    init = function(_config) {
        // Extend module config
        $.extend(true, config, _config || {});

        // Cache all elements for later use
        cacheElements();

        // DOM and event bindings
        setupBindings();

        // Pub/sub
        setupEvents();

        // Object-fit images
        objectFitPolyfill();

        setupTitleHeightCalculation();
        setupParallaxImages();
        setupContentPreviews();
        setupContentToggles();
        setupServices();
        setupBlog();
        cookieNotice();
        scrollingState();
    },



    cacheElements = function() {
        // Iterate over all selectors in config and store them as jQuery objects
        $.each(config.selectors, function(elementName, selector) {
            elements[elementName] = $(selector);
        });

        // Shortcuts
        selectors = config.selectors;
        $e = elements;
    },


    // DOM bindings
    setupBindings = function() {

        // Toggle mobile navigation
        $(document).on('click', selectors.navToggle, function(event){
        	$e.body.toggleClass('is-nav-visible');
        	event.preventDefault();
    	});

    	// Make anchor links scroll smoothly
    	$(document).on('click', selectors.smoothScrolling, function(event){
        	var $link = $(this),
        	    $target = $($link.attr('href')),
        	    scrollTo = $target.offset().top;

        	$('html, body').animate({scrollTop: scrollTo}, config.smoothScrollingSpeed);
        	event.preventDefault();
        });

        // Resizing? Save to body
        $(window).on('resizestart',   0, function(){ $e.body.addClass('is-resizing'); });
        $(window).on('resizestop', 1000, function(){ $e.body.removeClass('is-resizing'); });

        // Breakpoint change
        $.subscribe('/mediaquery/change', function(_, size) {
            if (MediaQuery.atLeast('small')) {

            }
        });

    	// Re-initialize page after ajax page loads
    	$(window).on('statechangecomplete', onStateChange);

        // "Fake" state change to initialize everything now
    	onStateChange();
    },

    // Observers for interaction with other modules
    setupEvents = function() {
        // Show and hide loading spinner when loading over Ajax
        $.subscribe('/ajax/start', function() { $e.body.addClass('is-loading'); });
        $.subscribe('/ajax/stop',  function() { $e.body.removeClass('is-loading'); });

        // Log all ajax errors to console
        $(document).ajaxError(function(event, jqXHR, settings, error) {
            console.error('Ajax request failed: ' + error + ' ('+settings.url+')');
        });
    },

    onStateChange = function() {
        openExternalLinksInNewTab();
        hideContentPreviews();
        hideContentToggles();
        serviceAnimation();
        objectFitServiceVideos();
    },

    pageHasTemplate = function(template) {
        return $e.body.hasClass('template-' + template);
    },




    trackCustomEvent = function(category, action, label){
        ga('send', 'event', category, action, label);
    },

    objectFitPolyfill = function () {
        if (Modernizr.objectfit) {
            return;
        }

        if (typeof objectFitImages !== 'function') {
            return;
        }

        var triggerObjectFit = function () {
            objectFitImages(null, {watchMQ: true});
        };

        triggerObjectFit();
        setTimeout(triggerObjectFit, config.objectFitInterval / 2);
        setInterval(triggerObjectFit, config.objectFitInterval);
    },

    objectFitServiceVideos = function () {
        if (Modernizr.objectfit) {
            // return;
        }
        if (typeof objectFitVideos === 'function') {
            console.log('Fitting videos');
            objectFitVideos(document.querySelectorAll('.service video'));
        }
    },

    openExternalLinksInNewTab = function () {
        $('a')
            .filter('[href^="http"], [href^="//"]')
            .not('[href*="' + window.location.host + '"]')
            .attr('rel', 'noopener noreferrer')
            .attr('target', '_blank');
    },

    calculateTitleHeight = function (){
        if (!$e.pageTitle.length) {
            return;
        }
        var height = $e.pageTitle.outerHeight();
        if (height < 5) {
            height = 0;
        }
        $e.body.get(0).style.setProperty('--title-height', height + 'px');
    },

    calculateBlogTitleHeight = function (){
        if (!$e.blogPreviewTitle.length) {
            return;
        }

        $e.body.get(0).style.setProperty('--blog-preview-title-height', '0px');
        var heights = $e.blogPreviewTitle.map(function() { return $(this).outerHeight(); }).get();
        var maxHeight = Math.max.apply(null, heights);

        $e.body.get(0).style.setProperty('--blog-preview-title-height', maxHeight + 'px');
    },

    setupTitleHeightCalculation = function () {
        var calc = function () {
            calculateTitleHeight();
            calculateBlogTitleHeight();
        };
        $(window).on('resizestop', 250, calc);
        calc();
    },

    setupContentToggles = function () {
        $(document).on('click', selectors.contentToggleButton, function(event){
            var $toggle = $(this),
                $container = $toggle.closest(selectors.contentToggleContainer),
                $content = $(selectors.contentToggleContent, $container),
                state = $container.data('state') || false;

            state = !state;

            if (state) {
                $content.slideDown(600);
                if (!MediaQuery.atLeast('medium')) {
                    setTimeout(function () {
                        // var scrollTop = $content.offset().top - ($(window).height() / 2);
                        // $('html, body').animate({scrollTop: scrollTop}, 300);
                    }, 600);
                }
            }
            else {
                $content.slideUp(600);
            }

            $container.data('state', state);
            $container.toggleClass('is-toggled', !!state);
        });
    },

    hideContentToggles = function () {
        $(selectors.contentToggleContent).slideUp(0);
    },

    setupContentPreviews = function () {
        $(document).on('click', selectors.contentPreviewToggle, function(event){
            var $toggle = $(this),
                $container = $toggle.closest(selectors.contentPreviewContainer),
                $intro = $(selectors.contentPreviewIntro, $container),
                $content = $(selectors.contentPreviewContent, $container),
                state = $container.data('state') || false;

            state = !state;

            if (state) {
                $intro.slideUp(600);
                $content.slideDown(600);
                if (!MediaQuery.atLeast('medium')) {
                    setTimeout(function () {
                        var scrollTop = $content.offset().top - ($(window).height() / 2);
                        // $('html, body').animate({scrollTop: scrollTop}, 300);
                    }, 600);
                }
            }
            else {
                $intro.slideDown(600);
                $content.slideUp(600);
            }

            $container.data('state', state);
            $container.toggleClass('is-toggled', !!state);
        });
    },

    hideContentPreviews = function () {
        $(selectors.contentPreviewContent).slideUp(0);
    },

    scrollingState = function () {
        $(window)
            .on('scrollstart', function() {
                $e.body.addClass('is-scrolling');
                $e.body.removeClass('is-not-scrolling');
            })
            .on('scrollstop', function() {
                $e.body.removeClass('is-scrolling');
                $e.body.addClass('is-not-scrolling');
            });
    },

    serviceAnimation = function () {
        var $list = $(selectors.processList);
        if (!$list.length) {
            return;
        }

        $e.body.removeClass('is-process-list-in-view');

        setTimeout(function(){
            var waypoints = $list.waypoint(function(direction) {
                console.log(this.element.id + ' hit 25% from bottom of window');
                $(this.element).addClass('is-in-view');
                setTimeout(function(){
                    $e.body.addClass('is-process-list-in-view');
                }, 1500);
            }, {
                offset: '75%'
            });
        }, 750);
    },

    setupServices = function () {

        var $previews = $(selectors.servicePreview),
            $container = $e.serviceContainer;

        if (pageHasTemplate('template-service-list')) {
            $container.slideUp().data('closed', true);
        }

        // Swap in service when clicking link

        $e.serviceLink.click(function(event){
            event.preventDefault();

            var $link = $(this),
                $preview = $(selectors.servicePreview, $link),
                dataURL = $link.prop('href');

            $previews.removeClass('is-active');
            $preview.addClass('is-active');

            $.publish('/ajax/start');

            $.get(dataURL, function(data){
                var $container = $(selectors.serviceContainer),          // in current page's HTML
                    $results   = $(selectors.serviceContainer, $(data)); // in returned Ajax HTML

                if ($container.length && $results.length) {

                    // Swap out results
                    $container.fadeOut(500, function() {
                        $container.html($results.html()).fadeIn(500);
                        if ($container.data('closed')) {
                            $container.slideDown().data('closed', false);
                        }
                        onStateChange();
                    });
                    if (!MediaQuery.atLeast('medium')) {
                        setTimeout(function () {
                            var scrollTop = $container.offset().top - ($(window).height() / 5);
                            $('html, body').animate({scrollTop: scrollTop}, config.smoothScrollingSpeed);
                        }, 100);
                    }

                    history.replaceState({}, null, dataURL);
                }
                else {
                    window.location.replace(dataURL);
                }

            }).fail(function() {
                window.location.replace(dataURL);
            }).always(function() {
                $.publish('/ajax/stop');
            });
        });
    },

    scrollToBlogPreview = function ($preview) {
        if ($e.blogList.length && $preview && $preview.length) {
            var newLeft = $preview.offset().left + $e.blogList.scrollLeft();
            $e.blogList.animate({scrollLeft: newLeft}, config.smoothScrollingSpeed);
        }
    },

    setupBlog = function () {

        // Make vertical scrollwheel trigger horizontal scrolling

        $e.blogList.mapVerticalScrollToHorizontal();
        var scrollLeft;
        $e.blogList.on('scroll', _.throttle(function() {
            scrollLeft = $e.blogList.scrollLeft();
            $e.body.toggleClass('is-blog-scrolled-right', scrollLeft > config.scrollTolerance);
        }, 200));

        scrollToBlogPreview($e.activeBlogPreview);

        // AJAX-load posts

        var $previews = $(selectors.blogPreview),
            $container = $e.blogContainer,
            blogHomeUrl = $e.blogList.data('home-url');

        if (pageHasTemplate('template-blog-list')) {
            $container.slideUp().data('closed', true);
        }

        // Close blog post and return to main view when clicking categories

        $e.blogCategoryPreview.click(function(event){
            event.preventDefault();
            $previews.removeClass('is-active');

            $e.body.removeClass('template-blog-post');
            $e.body.addClass('template-blog-list');
            history.replaceState({}, null, blogHomeUrl);
        });

        // Swap in blog post when clicking link

        $e.blogLink.click(function(event){
            event.preventDefault();
            event.stopPropagation();

            var $link = $(this),
                $preview = $link.closest(selectors.blogPreview),
                dataURL = $link.prop('href');

            $previews.removeClass('is-active');
            $preview.addClass('is-active');

            $.publish('/ajax/start');

            $.get(dataURL, function(data){
                var $container = $(selectors.blogContainer),          // in current page's HTML
                    $results   = $(selectors.blogContainer, $(data)); // in returned Ajax HTML

                if ($container.length && $results.length) {

                    // Swap out results
                    $container.fadeOut(500, function() {
                        $container.html($results.html()).fadeIn(500);
                        if ($container.data('closed')) {
                            $container.slideDown().data('closed', false);
                        }
                    });

                    scrollToBlogPreview($preview);

                    $e.body.addClass('template-blog-post');
                    $e.body.removeClass('template-blog-list');
                    history.replaceState({}, null, dataURL);
                    onStateChange();

                }
                else {
                    window.location.replace(dataURL);
                }

            }).fail(function() {
                window.location.replace(dataURL);
            }).always(function() {
                $.publish('/ajax/stop');
            });
        });
    },


    lazyLoadedImagesLoaded = function (el, each, complete) {
        var $tag = $(el);
        var config = {
            attributes: true,
            childList: false,
            characterData: false,
            subtree: true,
            attributeFilter: ['class']
        };

        var observer = new MutationObserver(function (mutations){
            if(!$tag.find('.lazyloading, .lazyload').length){
                observer.disconnect();
                if (typeof complete === 'function') {
                    complete();
                }
            }
        });

        observer.observe(el, config);
    },

    setupParallaxImages = function () {
        var $objects = $(selectors.parallaxImage);
        if (!$objects.length) {
            return;
        }
        var rellax = new Rellax(selectors.parallaxImage, {
            // speed: -2,
            center: true,
            //round: true,
        });
        $objects.addClass('is-setup');

        $objects.each(function(i){
            var $object = $(this);
            var $images = $object.find('img');
            $images.addClass('lazypreload');

            if ($images.length > 1) {

                $object.addClass('is-sequence');

                $object.allLazyLoaded(function() {
                    animateImageSequence($images);
                    setTimeout(function(){
                        $object.removeClass('has-not-loaded').addClass('has-loaded');
                    }, 250);
                });
            }
        });
    },

    animateImageSequence = function ($images, options) {
        var defaultOptions = {
            autoplay: true,
            framerate: 24,
            reversed: false,
            onframe: null,
            onloop: null,
        };

        options = $.extend(true, defaultOptions, options || {});

        var imageCount = $images.length,
            imagesLoaded = imageCount,
            iterator = 0,
            prevIterator = 0,
            reversing = false,
            loopCount = 0;

        function iterate() {
            prevIterator = iterator;
            if (reversing) {
                if (iterator <= 0) {
                    reversing = false;
                } else {
                    iterator -= 1;
                }
            } else {
                iterator += 1;
                if (iterator >= imageCount) {
                    if (options.reversed) {
                        reversing = true;
                    } else {
                        iterator = 0;
                    }
                }
            }
        }

        function process_image() {
            var $image = $images.eq(iterator),
                $prevImage = $images.eq(prevIterator);

            if ($image && $image.length && $prevImage && $prevImage.length) {
                $prevImage.removeClass('is-current-frame');
                $image.addClass('is-current-frame');
            }
        }


        /* Frame Rate adjustments thanks to Rishabh at http://codetheory.in/controlling-the-frame-rate-with-requestanimationframe/ */
        var then = Date.now();
        var request;

        function timeout_interval() {
            return 1000 / options.framerate;
        }

        function loop() {
            request = window.requestAnimationFrame(loop);
            if (imagesLoaded < imageCount) return;
            var now = Date.now();
            var delta = now - then;
            var interval = timeout_interval();
            if (delta > interval) {
                then = now - (delta % interval);
                process_image();
                if (options.onframe) {
                    options.onframe(iterator + 1, imageCount);
                }
                if (options.onloop && imageCount === iterator + 1) {
                    loopCount++;
                    options.onloop(loopCount);
                }
                iterate();
            }
        }

        function set_reversed(reversed) {
            options.reversed = reversed;
        }

        function set_framerate(framerate) {
            options.framerate = Math.abs(framerate);
            interval = 1000 / options.framerate;
        }

        function set_onframe(onframe) {
            options.onframe = onframe;
        }

        function stop() {
            window.cancelAnimationFrame(request);
        }

        if (options.autoplay) {
            loop();
        }
    },

    cookieNotice = function () {
        // Disable, not using cookies
        return;
        $e.body.toggleClass('is-cookie-notice-visible', document.cookie.indexOf('hidecookienotice=1') === -1);
        $e.cookieAccept.click(function() {
            var cookieDate = new Date();
            cookieDate.setTime(cookieDate.getTime()+(1*24*60*60*1000)); // 1 day
            document.cookie = "hidecookienotice=1; expires="+cookieDate.toGMTString()+"; path=/";
            $e.body.removeClass('is-cookie-notice-visible');
        });
    };


    // Public properties and methods
    return {
        init: init,
    };

})();

// General site/app module

$(function() {

    // Initialize media query detection

    MediaQuery.init();

    // Load up app and initialize

    App.init();

});
